import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
// import { trigger, transition, animate, style, query, stagger } from '@angular/animations';
import { PageScrollService } from 'ngx-page-scroll-core';

// var didScroll;
// var lastScrollTop = 0;
// var delta = 5;
// var navbarHeight = 0;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    isShow: boolean;
    topPosToStartShowing = 100;
    private _router: Subscription;
    params: Params;
    filtro_valor: string = "";

    constructor( private pageScrollService: PageScrollService, private renderer : Renderer2, private router: Router, @Inject(DOCUMENT,) private document: any, private element : ElementRef, private _http: HttpClient, public location: Location, private route: ActivatedRoute ) {}
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
      
        // window scroll top
        // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        
        if (scrollPosition >= this.topPosToStartShowing) {
            this.isShow = true;
        } else {
            this.isShow = false;
        }
    }
    // TODO: Cross browsing
    gotoTop() {
        window.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
        });
    }
    ngOnInit() {
      this._http.get('/assets/json/emprendedores.json').subscribe( (users: any[]) => this.usuarios = users );
      this.route.queryParams.subscribe((params: Params) => {
        this.params = params;
        const id = params['id'];
        this.pageScrollService.scroll({
            document: this.document,
            scrollTarget: '#emprendimiento-'+id,
          });
        const buscar = params['buscar'];
        this.filtro_valor = buscar;
      });
    }
    ngOnDestroy() {
       
    }
    usuarios = []
    handleSearch(value: string) {
        this.filtro_valor = value;
        this.location.replaceState("/home?buscar="+value);
    }
    
}
