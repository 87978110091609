import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
// import { NouisliderModule } from 'ng2-nouislider';
// import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { SectionsComponent } from './sections.component';


@NgModule({
  declarations: [
    SectionsComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    FormsModule,
    // NgbModule,
    RouterModule,
    // NouisliderModule,
    // JwBootstrapSwitchNg2Module
  ],
  exports:[ SectionsComponent ]
})
export class SectionsModule { }
