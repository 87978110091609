import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;
    constructor(private _http: HttpClient) {  }
    
    ngOnInit() {
        this._http.get('/assets/json/emprendedores.json').subscribe( (users: any[]) => this.usuarios = users );
    }
    usuarios = [];
}
