import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(lista: any[], texto: string): any[] {
    if (!texto) return lista;
    return lista.filter(user => (user.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + user.representante.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + user.description.normalize("NFD").replace(/[\u0300-\u036f]/g, "")).toUpperCase().includes((texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "")).toUpperCase()))
  }
}
